import { Space } from "@mantine/core";
import { DashboardNewCorrections } from "./Dashboard/DashboardNewCorrections";
import { DashboardNewPost } from "./Dashboard/DashboardNewPost";
import { DashboardPosts } from "./Dashboard/DashboardPosts";
import { DashboardPostsPrompt } from "./Dashboard/DashboardPostsPrompt";

interface DashboardProps {
  numNewCorrections: number;
  myPostsCount: number;
}

export function Dashboard({
  numNewCorrections,
  myPostsCount,
}: DashboardProps): JSX.Element {
  return (
    <>
      {numNewCorrections > 0 && (
        <>
          <DashboardNewCorrections numNewCorrections={numNewCorrections} />
          <Space h="lg" />
        </>
      )}
      <DashboardNewPost myPostsCount={myPostsCount} />
      <Space h="lg" />
      <DashboardPostsPrompt />
      <Space h="lg" />
      <DashboardPosts />
    </>
  );
}
