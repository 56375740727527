import { Button, Tooltip, createStyles } from "@mantine/core";
import { Dispatch } from "react";
import { MessagePlus } from "tabler-icons-react";
import { Action } from "./SentenceForCorrection";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    tooltip: {
      position: "relative",
      top: "3px",
      right: "4px",
    },
  };
});

interface Props {
  dispatch: Dispatch<Action>;
}

export function CommentButton({ dispatch }: Props): JSX.Element {
  const { classes } = useStyles();

  return (
    <Tooltip label="Add comment" withArrow className={classes.tooltip}>
      <Button
        variant="subtle"
        compact
        onClick={() => dispatch({ type: "ADD_COMMENT" })}
      >
        <MessagePlus />
      </Button>
    </Tooltip>
  );
}
