import { Group } from "@mantine/core";
import { Dispatch } from "react";
import { AddCorrectionsButton } from "./AddCorrectionsButton";
import { CancelCorrectionsButton } from "./CancelCorrectionsButton";
import { CommentButton } from "./CommentButton";
import { FinishCorrectionsButton } from "./FinishCorrectionsButton";
import { PerfectButton } from "./PerfectButton";
import {
  Action,
  CommentStatus,
  CorrectionStatus,
} from "./SentenceForCorrection";

interface Props {
  sentence: string;
  correctionStatus: CorrectionStatus;
  commentStatus: CommentStatus;
  wasChanged: boolean;
  dispatch: Dispatch<Action>;
}

export function CorrectSentenceButtons({
  sentence,
  correctionStatus,
  commentStatus,
  wasChanged,
  dispatch,
}: Props): JSX.Element {
  return (
    <Group>
      {correctionStatus === "editing" ? (
        <>
          <FinishCorrectionsButton
            dispatch={dispatch}
            sentence={sentence}
            wasChanged={wasChanged}
          />
          <CancelCorrectionsButton dispatch={dispatch} sentence={sentence} />

          {commentStatus === "unstarted" && (
            <CommentButton dispatch={dispatch} />
          )}
        </>
      ) : (
        <>
          <AddCorrectionsButton
            correctionsUnstarted={correctionStatus === "unstarted"}
            dispatch={dispatch}
          />
          <PerfectButton
            correctionsUnstarted={correctionStatus === "unstarted"}
            dispatch={dispatch}
          />
        </>
      )}
    </Group>
  );
}
