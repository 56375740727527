import { Button, Tooltip } from "@mantine/core";
import { Dispatch } from "react";
import { Pencil } from "tabler-icons-react";
import { Action } from "./SentenceForCorrection";

interface Props {
  correctionsUnstarted: boolean;
  dispatch: Dispatch<Action>;
}

export function AddCorrectionsButton({
  correctionsUnstarted,
  dispatch,
}: Props): JSX.Element | null {
  if (!correctionsUnstarted) {
    return null;
  }

  return (
    <Tooltip label="Make Corrections" withArrow>
      <Button
        compact
        color="yellow"
        variant="subtle"
        onClick={() => {
          const type = "ADD_CORRECTION";
          dispatch({ type });
        }}
      >
        <Pencil />
        {/* Correct */}
      </Button>
    </Tooltip>
  );
}
