import { useState } from "react";
import { createStyles, getStylesRef, Indicator, Navbar } from "@mantine/core";
import {
  Pencil,
  Settings,
  UserCircle,
  Logout,
  Inbox,
  EyeCheck,
  Icon,
} from "tabler-icons-react";
import { DarkModeToggle } from "./DarkModeToggle";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme, _params) => {
  const icon = getStylesRef("icon");

  return {
    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
    },

    linkText: {
      marginLeft: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.fn.rgba(theme.colors[theme.primaryColor][8], 0.25)
            : theme.colors[theme.primaryColor][0],
        color:
          theme.colorScheme === "dark"
            ? theme.white
            : theme.colors[theme.primaryColor][7],
        [`& .${icon}`]: {
          color:
            theme.colors[theme.primaryColor][
              theme.colorScheme === "dark" ? 5 : 7
            ],
        },
      },
    },
  };
});

interface NavLinkInfo {
  link: string;
  label: string;
  icon: Icon;
  count?: number;
}

function navLinkInfo(numNewCorrections: number): NavLinkInfo[] {
  return [
    // { link: "", label: "Dashboard", icon: Dashboard },
    { link: "write", label: "Practice Writing", icon: Pencil },
    { link: "", label: "Proofread", icon: EyeCheck },
    {
      link: "corrections",
      label: "Corrections For Me",
      icon: Inbox,
      count: numNewCorrections,
    },
    { link: "settings", label: "Settings", icon: Settings },
  ];
}

interface Props {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  numNewCorrections: number;
}

export function AppNavbar({
  opened,
  setOpened,
  numNewCorrections,
}: Props): JSX.Element {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Write");

  function clickLink(label: string): void {
    if (active === label) {
      return;
    }

    setActive(label);
    if (opened) {
      setOpened(false);
    }
  }

  const links = navLinkInfo(numNewCorrections).map((item) => {
    const icon = <item.icon className={classes.linkIcon} />;

    return (
      <Link
        className={cx(classes.link, {
          [classes.linkActive]: item.label === active,
        })}
        to={item.link}
        key={item.label}
        onClick={(_event) => {
          clickLink(item.label);
        }}
      >
        {item.count ? (
          <Indicator position={"top-end"} label={item.count} size={13}>
            {icon}
          </Indicator>
        ) : (
          icon
        )}
        <span className={classes.linkText}>{item.label}</span>
      </Link>
    );
  });

  return (
    <Navbar
      height={700}
      width={{ sm: 240, lg: 300 }}
      hidden={!opened}
      hiddenBreakpoint="sm"
      p="md"
    >
      <Navbar.Section grow>{links}</Navbar.Section>

      <Navbar.Section>
        <DarkModeToggle />
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Link
          to="/account"
          className={classes.link}
          onClick={(event) => event.preventDefault()}
        >
          <UserCircle className={classes.linkIcon} />
          <span>My account</span>
        </Link>

        <Link
          to="/logout"
          className={classes.link}
          onClick={(event) => event.preventDefault()}
        >
          <Logout className={classes.linkIcon} />
          <span>Logout</span>
        </Link>
      </Navbar.Section>
    </Navbar>
  );
}
