import { Title, Space, Stack, Button } from "@mantine/core";
import { Post } from "../../queries/posts";
import { PostComment } from "./PostComment";
import { SentenceForCorrection } from "./SentenceForCorrection";

interface Props {
  post: Post;
}

export function PostCorrecting({ post }: Props): JSX.Element {
  // TODO: extract the sentence splitting logic and make it more resilient
  const bodySentences = post.text
    .replace(/\n{2,}/g, "\n")
    .replace(/([.?!]) /g, "$1\n")
    .split("\n");
  const sentences = post.title ? [post.title, ...bodySentences] : bodySentences;

  return (
    <>
      <Title order={2}>Add corrections and comments</Title>
      <Space h="xl" />
      <Stack>
        {sentences.map((sentence, index) => (
          <SentenceForCorrection
            languageCode={post.languageCode}
            sentence={sentence}
            key={`${post.id}-sentenceForCorrection-${index}`}
          />
        ))}
      </Stack>
      <Space h="xl" />
      <Stack>
        <PostComment />
        <Button fullWidth>Post corrections</Button>
      </Stack>
    </>
  );
}
