import {
  Accordion,
  Button,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import {
  LanguageNameWithCode,
  LANGUAGE_TO_CODE_OPTIONS,
} from "../utils/language_codes";

export function Write(): JSX.Element {
  return (
    <>
      <Title>Write a little somethin'</Title>
      <Text>
        It can be whatever. A journal entry, homework for your language class, a
        rant about the world, or a story from your life. Then other users will
        check it out and give you feedback to help improve your writing.
      </Text>

      <Space h="md" />
      <Stack>
        <Select
          label="Language you're writing in"
          placeholder=""
          data={LANGUAGE_TO_CODE_OPTIONS.map(
            ([name, code]: LanguageNameWithCode) => ({
              value: code,
              label: name,
            })
          )}
          required
          searchable
        />
        <Textarea
          placeholder=""
          label="Your post"
          required
          autosize
          minRows={4}
        />
        <Textarea
          placeholder=""
          label="Native language version"
          description="Helps proofreaders understand what you meant"
          autosize
          minRows={4}
        />
        <TextInput label="Title" />
        <Accordion>
          <Accordion.Item value="help">
            <Accordion.Control>Writer's block?</Accordion.Control>
            <Accordion.Panel>
              Jot down a quick journal entry of what you did today. Or pick one
              of our essay prompts to get you you started.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Button>Publish</Button>
        <Button variant="subtle">Preview</Button>
      </Stack>
    </>
  );
}
