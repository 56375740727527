import { Group, Button, createStyles } from "@mantine/core";
import { Pencil } from "tabler-icons-react";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    topButtons: {
      width: "100%",
    },
    tooltip: {
      width: "100%",
    },
  };
});

interface Props {
  myPostsCount: number;
}

export function DashboardNewPost({ myPostsCount: number }: Props): JSX.Element {
  const { classes } = useStyles();

  const contents = (
    <Group position="center" grow>
      <Link to="/write">
        <Button leftIcon={<Pencil />} className={classes.topButtons}>
          Practice Writing
        </Button>
      </Link>
    </Group>
  );

  // return myPostsCount === 0 ? (
  //   <Tooltip
  //     label="Click here to get started"
  //     withArrow
  //     opened
  //     className={classes.tooltip}
  //     transition="fade"
  //     position="bottom"
  //   >
  //     {contents}
  //   </Tooltip>
  // ) : (
  //   contents
  // );
  return contents;
}
