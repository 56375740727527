import { Button, Tooltip } from "@mantine/core";
import { Dispatch } from "react";
import { Action } from "./SentenceForCorrection";

interface Props {
  sentence: string;
  dispatch: Dispatch<Action>;
  wasChanged: boolean;
}

export function FinishCorrectionsButton({
  sentence,
  dispatch,
  wasChanged,
}: Props) {
  const button = (
    <Button
      compact
      variant="light"
      disabled={!wasChanged}
      onClick={() => {
        dispatch({
          type: "FINISH_CORRECTION",
          payload: { sentence },
        });
      }}
    >
      Done
    </Button>
  );

  return !wasChanged ? (
    <Tooltip label="No changes made" withArrow>
      {button}
    </Tooltip>
  ) : (
    button
  );
}
