import DOMPurify from "dompurify";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// export function formatDate(date: Date) {
//   return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
// }

export function formatDateShort(date: Date) {
  return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
}

export function sanitizeSentence(text: string): string {
  return DOMPurify.sanitize(text, { ALLOWED_TAGS: ["del", "strong"] });
}

// export function markdownToHtml(markdown: string): string {
//   return DOMPurify.sanitize(marked(markdown), {
//     ALLOWED_TAGS: ["del", "strong"],
//   });
//   // const html = marked(markdown);
//   // return html;
// }
