import { Text, Space, createStyles } from "@mantine/core";
import { MessageCircle2 } from "tabler-icons-react";
import { CommentStatus, CorrectionStatus } from "./SentenceForCorrection";

const useStyles = createStyles((theme) => ({
  text: {
    borderRadius: theme.radius.sm,
    paddingTop: `calc(theme.spacing.xs / 2)`,
    paddingBottom: theme.spacing.xs,
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.xs,
    backgroundColor: theme.colors.gray[0],
  },

  icon: {
    position: "relative",
    top: "6px",
    marginRight: theme.spacing.xs,
  },
}));

interface Props {
  comment: string;
  commentStatus: CommentStatus;
  correctionStatus: CorrectionStatus;
}

export function SentenceComment({
  comment,
  commentStatus,
  correctionStatus,
}: Props): JSX.Element | null {
  const { classes } = useStyles();

  if (correctionStatus !== "done") {
    return null;
  }
  if (commentStatus === "unstarted") {
    return null;
  }
  if (comment.trim().length === 0) {
    return null;
  }

  return (
    <>
      <Space my="xl" />
      <Text className={classes.text}>
        <MessageCircle2 className={classes.icon} />
        {comment}
      </Text>
    </>
  );
}
