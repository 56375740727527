import {
  Burger,
  Header,
  MediaQuery,
  useMantineTheme,
  Code,
  Space,
} from "@mantine/core";
import { Link } from "react-router-dom";

export function AppHeader({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const theme = useMantineTheme();

  return (
    <Header height={70} p="md">
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Link to="/">
          <img
            src="logo-min.svg"
            width={120}
            alt="Longhand"
            style={{ position: "relative", top: "1px" }}
          ></img>
        </Link>
        <Space w="xs" />

        <Code sx={{ fontWeight: 700 }}>Alpha</Code>
      </div>
    </Header>
  );
}
