import {
  AppShell,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { AppNavbar } from "./AppNavbar";
import { AppHeader } from "./AppHeader";
import { useState } from "react";
import { useColorScheme } from "@mantine/hooks";
import { Outlet } from "react-router-dom";

interface AppProps {
  numNewCorrections: number;
}

export function App({ numNewCorrections }: AppProps): JSX.Element {
  const [opened, setOpened] = useState(false);
  // hook will return either 'dark' or 'light' on client
  // and always 'light' during ssr as window.matchMedia is not available
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] =
    useState<ColorScheme>(preferredColorScheme);
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme: colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <AppShell
          navbarOffsetBreakpoint="sm"
          fixed
          header={<AppHeader opened={opened} setOpened={setOpened} />}
          navbar={
            <AppNavbar
              opened={opened}
              setOpened={setOpened}
              numNewCorrections={numNewCorrections}
            />
          }
        >
          <Outlet />
        </AppShell>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
