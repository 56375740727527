import { fakePost } from "../fakes/posts";
import { LanguageCode } from "../utils/language_codes";

export interface Post {
  id: string;
  title?: string;
  text: string;
  nativeText?: string;
  languageCode: LanguageCode;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  username: string;
  numComments: number;
  numCorrections: number;
  numLikes: number;
  prompt?: string;
}

export function findPost(id: string): Post {
  return fakePost();
}

const MAX_RANDOM_POSTS = 10;
export function getPosts(): Post[] {
  const numPosts = Math.floor(Math.random() * MAX_RANDOM_POSTS);

  let posts: Post[] = [];
  for (let i = 0; i < numPosts; i++) {
    posts.push(fakePost());
  }

  return posts;
}

const MAX_NUM_MY_POSTS = 2;
export function getMyPostsCount(): number {
  return Math.floor(Math.random() * MAX_NUM_MY_POSTS);
}
