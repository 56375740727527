import { Card, Indicator, Space } from "@mantine/core";
import { Change, diffWordsWithSpace } from "diff";
import { useReducer, useRef } from "react";
import { CorrectSentenceButtons } from "./CorrectSentenceButtons";
import { CorrectedSentence } from "./CorrectedSentence";
import { OriginalSentence } from "./OriginalSentence";
import { CorrectSentenceField } from "./CorrectSentenceField";
import { LanguageCode } from "../../utils/language_codes";
import { CommentField } from "./CommentField";
import { SentenceComment } from "./SentenceComment";

function getChanges(original: string, corrected: string): Change[] {
  return diffWordsWithSpace(original, corrected);
}

function checkIfWasChanged(
  sentence: string,
  correctedSentence: string
): boolean {
  return sentence.trim() !== correctedSentence.trim();
}

export type Action =
  | {
      type: "MARK_AS_PERFECT";
    }
  | {
      type: "ADD_CORRECTION";
    }
  | {
      type: "EDIT_CORRECTION";
    }
  | {
      type: "CORRECTION_UPDATED";
      payload: {
        correctedSentence: string;
      };
    }
  | {
      type: "CANCEL_CORRECTION";
      payload: {
        originalSentence: string;
      };
    }
  | {
      type: "FINISH_CORRECTION";
      payload: {
        sentence: string;
      };
    }
  | {
      type: "ADD_COMMENT";
    }
  | {
      type: "COMMENT_UPDATED";
      payload: {
        comment: string;
      };
    };

export type CorrectionStatus = "unstarted" | "editing" | "done" | "perfect";
interface State {
  correctedSentence: string;
  correctionStatus: CorrectionStatus;
  commentStatus: CommentStatus;
  comment: string;
}

export type CommentStatus = "unstarted" | "writing" | "done";

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "MARK_AS_PERFECT": {
      return {
        ...state,
        correctionStatus: "perfect",
      };
    }

    case "ADD_CORRECTION": {
      return {
        ...state,
        correctionStatus: "editing",
      };
    }

    case "EDIT_CORRECTION": {
      const correctionStatus =
        state.correctionStatus === "perfect" ? "unstarted" : "editing";

      return {
        ...state,
        correctionStatus,
      };
    }

    case "FINISH_CORRECTION": {
      const { sentence } = action.payload;
      const changed = checkIfWasChanged(sentence, state.correctedSentence);
      const correctedSentence = changed ? state.correctedSentence : sentence;
      const correctionStatus = changed ? "done" : "unstarted";

      return {
        ...state,
        correctionStatus,
        correctedSentence,
      };
    }

    case "CANCEL_CORRECTION": {
      const { originalSentence } = action.payload;

      return {
        ...state,
        correctedSentence: originalSentence,
        correctionStatus: "unstarted",
        comment: "",
      };
    }

    case "CORRECTION_UPDATED": {
      const { correctedSentence } = action.payload;

      return {
        ...state,
        correctedSentence,
      };
    }

    case "ADD_COMMENT": {
      return {
        ...state,
        commentStatus: "writing",
      };
    }

    case "COMMENT_UPDATED": {
      const { comment } = action.payload;

      return {
        ...state,
        comment,
      };
    }

    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

function getIndicatorColor(correctionStatus: CorrectionStatus): string | null {
  switch (correctionStatus) {
    case "perfect":
      return "green";
    case "done":
      return "yellow";
    default:
      return null;
  }
}

function getIndicatorText(correctionStatus: CorrectionStatus): string | null {
  switch (correctionStatus) {
    case "perfect":
      return "Perfect";
    case "done":
      return "Corrected";
    default:
      return null;
  }
}

function getIndicatorLeft(correctionStatus: CorrectionStatus):
  | {
      indicator: {
        left: string;
      };
    }
  | {} {
  switch (correctionStatus) {
    case "perfect":
      return { indicator: { left: "18px" } };
    case "done":
      return { indicator: { left: "26px" } };
    default:
      return {};
  }
}

function showCorrectedSentence(correctionStatus: CorrectionStatus): boolean {
  return correctionStatus === "done" || correctionStatus === "editing";
}

function showCorrectionField(correctionStatus: CorrectionStatus): boolean {
  return correctionStatus === "editing";
}

function showControls(correctionStatus: CorrectionStatus): boolean {
  return correctionStatus === "unstarted" || correctionStatus === "editing";
}

function showCommentField(commentStatus: CommentStatus): boolean {
  return commentStatus === "writing" || commentStatus === "done";
}

function canEditAgain(correctionStatus: CorrectionStatus): boolean {
  return correctionStatus === "done" || correctionStatus === "perfect";
}

interface Props {
  languageCode: LanguageCode;
  sentence: string;
}

export function SentenceForCorrection({
  sentence,
  languageCode,
}: Props): JSX.Element {
  // ref
  const correctionFieldRef = useRef<HTMLTextAreaElement>(null);

  // state
  const initialState: State = {
    correctedSentence: sentence,
    correctionStatus: "unstarted",
    commentStatus: "unstarted",
    comment: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { correctedSentence, correctionStatus, commentStatus, comment } = state;

  // calculated
  const wasChanged = checkIfWasChanged(sentence, correctedSentence);
  const changes = getChanges(sentence, correctedSentence);

  const card = (
    <Card
      withBorder={true}
      radius={"md"}
      shadow={"xs"}
      onClick={() => {
        if (canEditAgain(correctionStatus)) {
          if (correctionFieldRef.current) {
            const textarea: HTMLTextAreaElement = correctionFieldRef.current;
            textarea.focus();
          }
          return dispatch({ type: "EDIT_CORRECTION" });
        }
      }}
    >
      <OriginalSentence
        correctionStatus={correctionStatus}
        languageCode={languageCode}
        changes={changes}
      />
      {showCorrectedSentence(correctionStatus) && (
        <>
          <Space my="xs" />
          <CorrectedSentence
            languageCode={languageCode}
            correctionStatus={correctionStatus}
            changes={changes}
          />
          <SentenceComment
            correctionStatus={correctionStatus}
            commentStatus={commentStatus}
            comment={comment}
          />
        </>
      )}
      {showCorrectionField(correctionStatus) && (
        <>
          <Space my="xl" />
          <CorrectSentenceField
            languageCode={languageCode}
            correctionStatus={correctionStatus}
            correctedSentence={correctedSentence}
            dispatch={dispatch}
            textareaRef={correctionFieldRef}
          />
          {showCommentField(commentStatus) && (
            <>
              <Space my="xl" />
              <CommentField comment={comment} dispatch={dispatch} />
            </>
          )}
        </>
      )}
      {showControls(correctionStatus) && (
        <>
          <Space my="md" />
          <CorrectSentenceButtons
            sentence={sentence}
            correctionStatus={correctionStatus}
            commentStatus={commentStatus}
            wasChanged={wasChanged}
            dispatch={dispatch}
          />
        </>
      )}
    </Card>
  );

  const indicatorColor = getIndicatorColor(correctionStatus);
  const indicatorText = getIndicatorText(correctionStatus);
  const indicatorStyles = getIndicatorLeft(correctionStatus);

  return indicatorColor ? (
    <Indicator
      color={indicatorColor}
      label={indicatorText}
      position="top-start"
      withBorder
      size={18}
      offset={2}
      styles={indicatorStyles}
    >
      {card}
    </Indicator>
  ) : (
    card
  );
}
