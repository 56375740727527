import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./index.css";
import { App } from "./layout/App";
import reportWebVitals from "./reportWebVitals";
import { getMyPostsCount } from "./queries/posts";
import { getNumNewCorrections } from "./queries/corrections";
import { Corrections } from "./routes/Corrections";
import { Dashboard } from "./routes/Dashboard";
import { Proofread } from "./routes/Proofread";
import { Write } from "./routes/Write";
import { Settings } from "./routes/Settings";
import { Post } from "./routes/Post";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const numNewCorrections = getNumNewCorrections();
const myPostsCount = getMyPostsCount();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App numNewCorrections={numNewCorrections} />}>
          <Route
            path=""
            element={
              <Dashboard
                numNewCorrections={numNewCorrections}
                myPostsCount={myPostsCount}
              />
            }
          />
          <Route path="write" element={<Write />} />
          <Route path="correct" element={<Proofread />} />
          <Route path="corrections" element={<Corrections />} />
          <Route path="posts/:id" element={<Post />} />
          <Route path="settings" element={<Settings />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                {/* TODO: 404 message */}
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
