// import {
//   useQuery,
//   useMutation,
//   useQueryClient,
//   QueryClient,
//   QueryClientProvider,
// } from "react-query";
import { Stack } from "@mantine/core";
import { DashboardPost } from "./DashboardPost";
import { getPosts } from "../../queries/posts";

export function DashboardPosts(): JSX.Element {
  const posts = getPosts();
  const postElements = posts.map((post) => {
    return <DashboardPost key={post.id} post={post} />;
  });

  return <Stack>{postElements}</Stack>;
}
