import { Textarea } from "@mantine/core";
import { MessageCircle2 } from "tabler-icons-react";

interface Props {}

export function PostComment(_props: Props) {
  return (
    <>
      <Textarea
        autosize
        description="Overall comments (optional)"
        placeholder="Good job!"
        icon={<MessageCircle2 size={14} />}
      />
    </>
  );
}
