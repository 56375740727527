import { Button } from "@mantine/core";
import { Dispatch } from "react";
import { Action } from "./SentenceForCorrection";

interface Props {
  sentence: string;
  dispatch: Dispatch<Action>;
}

export function CancelCorrectionsButton({ sentence, dispatch }: Props) {
  return (
    <Button
      compact
      color="gray"
      variant="subtle"
      onClick={() =>
        dispatch({
          type: "CANCEL_CORRECTION",
          payload: { originalSentence: sentence },
        })
      }
    >
      Cancel
    </Button>
  );
}
