import { Textarea } from "@mantine/core";
import { Dispatch, useEffect } from "react";
import { Pencil } from "tabler-icons-react";
import { LanguageCode } from "../../utils/language_codes";
import { Action, CorrectionStatus } from "./SentenceForCorrection";

interface Props {
  languageCode: LanguageCode;
  correctionStatus: CorrectionStatus;
  correctedSentence: string;
  dispatch: Dispatch<Action>;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

export function CorrectSentenceField({
  languageCode,
  correctionStatus,
  correctedSentence,
  dispatch,
  textareaRef,
}: Props): JSX.Element | null {
  useEffect(() => {
    if (textareaRef.current) {
      const textarea: HTMLTextAreaElement = textareaRef.current;

      // focus at end
      const end = textarea.value.length;
      textarea.setSelectionRange(end, end);
      textarea.focus();
    }
  }, [textareaRef]);

  if (correctionStatus !== "editing") {
    return null;
  }

  return (
    <Textarea
      ref={textareaRef}
      icon={<Pencil size={14} />}
      description="Corrected"
      autosize
      onChange={(e) => {
        // TODO: debounce this for better performance on cell phones
        dispatch({
          type: "CORRECTION_UPDATED",
          payload: { correctedSentence: e.target.value },
        });
      }}
      lang={languageCode}
      value={correctedSentence}
    />
  );
}
