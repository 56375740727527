import { Button, Tooltip } from "@mantine/core";
import { Dispatch } from "react";
import { Check } from "tabler-icons-react";
import { Action } from "./SentenceForCorrection";

interface Props {
  correctionsUnstarted: boolean;
  dispatch: Dispatch<Action>;
}

export function PerfectButton({
  correctionsUnstarted,
  dispatch,
}: Props): JSX.Element | null {
  if (!correctionsUnstarted) {
    return null;
  }

  return (
    <Tooltip label="Perfect!" withArrow>
      <Button
        compact
        color="green"
        variant="subtle"
        onClick={() => dispatch({ type: "MARK_AS_PERFECT" })}
      >
        <Check />
      </Button>
    </Tooltip>
  );
}
