import { Text, Title } from "@mantine/core";

export function DashboardPostsPrompt(): JSX.Element {
  return (
    <>
      <Title order={1}>Users waiting for feedback</Title>
      <Text>
        Proofread other users' writing to get faster feedback on your own.
      </Text>
    </>
  );
}
