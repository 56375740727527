import { LanguageCode, LANGUAGE_CODES } from "../utils/language_codes";

const DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "comcast.net",
  "me.com",
  "msn.com",
  "live.com",
  "sbcglobal.net",
  "ymail.com",
  "googlemail.com",
  "mail.ru",
  "bigpond.com",
  "zoho.com",
  "fastmail.fm",
  "mailcatch.com",
];

const NAMES = [
  "John",
  "Jane",
  "Mary",
  "Bob",
  "Tom",
  "Alice",
  "Sam",
  "Peter",
  "Linda",
  "Sara",
];

const USERNAMES = [
  "shon",
  "Coogle Dawg",
  "John Doe",
  "codey_out2",
  "dorax",
  "Xermilion",
  "Takumura",
  "Jack_xXx",
  "ippatsu",
  "kazuZ",
];

const LORUM_IPSUMS = [
  "Praesent vestibulum, ex nec commodo dignissim, felis ante rhoncus tellus, hendrerit condimentum diam nisl non justo.",
  "Ut ligula justo, sollicitudin in ullamcorper ut, cursus in purus.",
  "Aliquam vel nunc ac nunc vulputate viverra.",
  "Nullam at velit pharetra, varius lacus vitae, malesuada nunc.",
  "Sed id nisl et libero sollicitudin elementum.",
  "Duis dictum euismod posuere.",
  "Aenean ipsum nisl, ullamcorper vitae hendrerit a, bibendum nec diam.",
  "Curabitur ante felis, elementum vel eros non, volutpat viverra ligula.",
  "Praesent tempor mi quis enim consequat maximus.",
  "Vivamus ut hendrerit risus.",
  "Fusce quis urna vel ligula cursus finibus nec sit amet ex.",
  "Vivamus nec odio a neque consectetur vehicula.",
  "Donec at dui pulvinar est ultricies semper.",
  "Quisque porttitor elit mi, ullamcorper fermentum lacus pulvinar sit amet.",
  "Fusce malesuada, libero vel pharetra laoreet, purus mi ultrices lorem, a lobortis enim diam a sapien.",
  "Maecenas ullamcorper ex lacinia lorem suscipit ultrices.",
  "Sed tempor augue at risus bibendum, ut sollicitudin purus vestibulum.",
  "Mauris non est in risus volutpat ornare.",
  "Donec non arcu vel lectus eleifend hendrerit.",
  "Nunc auctor convallis dolor, eget scelerisque leo volutpat sed.",
  "Maecenas id turpis ut leo gravida feugiat nec nec tortor.",
  "Nullam condimentum urna tortor.",
  "Nulla sit amet massa quis tellus faucibus placerat.",
  "Aliquam erat volutpat.",
  "Interdum et malesuada fames ac ante ipsum primis in faucibus.",
  "Nulla porttitor diam diam, sit amet porta mi consectetur eu.",
  "Vestibulum interdum nulla id nibh scelerisque, in posuere mauris luctus.",
  "Suspendisse porta arcu viverra quam viverra auctor.",
  "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  "Sed sed sodales ligula, eu fringilla risus.",
  "Duis suscipit pulvinar nisi, nec rutrum mi mattis sit amet.",
  "Curabitur scelerisque consectetur eleifend.",
  "Etiam eleifend ex elit, vitae bibendum dolor maximus quis.",
  "Proin aliquet pretium consectetur.",
  "Curabitur ornare nulla a sapien cursus gravida.",
  "Mauris convallis vulputate auctor.",
  "In et tortor eu nisi convallis molestie.",
  "Nullam eu efficitur est.",
  "Curabitur faucibus risus felis, sit amet gravida nisi consequat pharetra.",
  "Praesent mi ipsum, faucibus sollicitudin sollicitudin id, gravida quis enim.",
  "Mauris id orci fringilla, euismod leo nec, malesuada enim.",
  "In pellentesque dapibus est dictum dignissim.",
  "Ut et sapien ultrices, tincidunt orci vitae, imperdiet leo.",
  "Mauris porta turpis ullamcorper, tincidunt dolor ac, consequat odio.",
  "Donec eleifend risus in orci blandit rhoncus.",
  "Integer interdum est at fermentum scelerisque.",
  "Quisque rhoncus congue venenatis.",
  "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  "Fusce facilisis in nunc finibus lobortis.",
  "Proin porta, nulla vitae ultrices ultricies, massa ante viverra mauris, viverra gravida enim sem sed risus.",
  "Pellentesque pulvinar interdum nisi, id tincidunt augue pharetra id.",
  "Aenean ac elementum nulla.",
  "Suspendisse elementum congue nibh ac aliquam.",
  "Vivamus lobortis enim id ante tristique bibendum.",
  "Integer convallis, erat sed ullamcorper vulputate, massa leo aliquam enim, id gravida tortor nisl sit amet quam.",
  "Vivamus consequat purus ac dapibus bibendum.",
  "Phasellus imperdiet tristique nunc, eget rhoncus tellus suscipit vitae.",
  "Donec vulputate dui neque, sed facilisis magna pulvinar non.",
  "Sed lorem magna, cursus at ante ac, vulputate aliquam purus.",
  "Ut ipsum ligula, imperdiet eu arcu condimentum, accumsan hendrerit ex.",
  "Nunc consequat quis nulla at vehicula.",
  "Duis imperdiet ante vehicula urna cursus ultricies.",
];

function randomFrom<T>(list: T[]): T {
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}

export function fakeName(): string {
  return randomFrom(NAMES);
}

export function fakeUsername(): string {
  return randomFrom(USERNAMES);
}

export function fakeSentence(): string {
  return randomFrom(LORUM_IPSUMS);
}

const MAX_NUM_SENTENCES_PARAGRAPH = 5;
export function fakeParagraph(numSentences?: number): string {
  if (!numSentences) {
    numSentences = Math.floor(Math.random() * MAX_NUM_SENTENCES_PARAGRAPH + 1);
  }

  return [...Array(numSentences)].map(() => fakeSentence()).join(" ");
}

const MAX_POST_NUM_PARAGRAPHS = 5;
export function fakePostText(numParagraphs?: number): string {
  if (!numParagraphs) {
    numParagraphs = Math.floor(Math.random() * MAX_POST_NUM_PARAGRAPHS + 1);
  }

  return [...Array(numParagraphs)].map(() => fakeParagraph()).join("\n\n");
}

export function fakeUuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function fakeDate(from?: Date, to?: Date): Date {
  // defaults
  const now = new Date();
  to ??= now;
  from ??= new Date();
  from.setDate(from.getDate() - 7);

  const fromTime = from.getTime();
  const toTime = to.getTime();
  return new Date(fromTime + Math.random() * (toTime - fromTime));
}

export function fakeEmail(): string {
  return `${fakeUsername()}@${randomFrom(DOMAINS)}`;
}

export function fakeLanguageCode(): LanguageCode {
  return randomFrom(LANGUAGE_CODES);
}

export function randomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
