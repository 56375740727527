import { createStyles, Text } from "@mantine/core";
import { Change } from "diff";
import { sanitizeSentence } from "../../utils/formatting";
import { LanguageCode } from "../../utils/language_codes";
import { CorrectionStatus } from "./SentenceForCorrection";

const useStyles = createStyles((theme, _params, getRef) => {
  // const icon = getRef("icon");

  return {
    correctedSentence: {
      strong: {
        color: "#32b643",
        fontWeight: 500,
      },
    },
  };
});

// TODO: use map function instead of for loop
function getCorrectedSentenceMarkdown(changes: Change[]): string {
  let markdown = "";

  for (const change of changes) {
    if (change.added || change.removed) {
      // bold added text
      if (change.added) {
        markdown += `<strong>${change.value}</strong>`;
      }
    } else {
      markdown += change.value;
    }
  }

  return markdown;
}

interface Props {
  languageCode: LanguageCode;
  correctionStatus: CorrectionStatus;
  changes: Change[];
}

export function CorrectedSentence({
  languageCode,
  correctionStatus,
  changes,
}: Props): JSX.Element | null {
  const { classes } = useStyles();

  if (!(correctionStatus === "editing" || correctionStatus === "done")) {
    return null;
  }

  const correctedMarkdown = getCorrectedSentenceMarkdown(changes);

  return (
    <Text
      lang={languageCode}
      className={classes.correctedSentence}
      dangerouslySetInnerHTML={{
        __html: sanitizeSentence(correctedMarkdown),
      }}
    />
  );
}
