import { Space, Divider, Button } from "@mantine/core";
import { useParams } from "react-router-dom";
import { Heart } from "tabler-icons-react";
import { findPost } from "../queries/posts";
import { PostCorrecting } from "./Post/PostCorrecting";
import { PostDisplay } from "./Post/PostDisplay";

export function Post(): JSX.Element {
  const params = useParams();
  const postId = params.id;
  if (!postId) {
    throw new Error("No post id");
  }
  const post = findPost(postId);

  return (
    <>
      <PostDisplay post={post} />

      <Space h="xl" />
      <Button variant="subtle" compact>
        <Heart size={16} />
        &nbsp; Like
      </Button>

      {/* divider */}
      <Space h="xl" />
      <Divider my="sm" variant="dashed" />
      <Space h="xl" />

      <PostCorrecting post={post} />
    </>
  );
}
