import { Avatar, Group, Space, Stack, Text, Title } from "@mantine/core";
import { Post } from "../../queries/posts";
import { formatDateShort } from "../../utils/formatting";

interface Props {
  post: Post;
}

export function PostDisplay({ post }: Props): JSX.Element {
  const textParagraphs = post.text.split("\n\n");
  const nativeTextParagraphs = post.nativeText
    ? post.nativeText.split("\n\n")
    : [];

  const texts = textParagraphs.map((text, index) => (
    <Text key={`${post.id}-paragraph-${index}`}>{text}</Text>
  ));
  const nativeTexts = nativeTextParagraphs.map((text, index) => (
    <Text key={`${post.id}-paragraphNative-${index}`}>{text}</Text>
  ));

  return (
    <>
      {post.title ? (
        <>
          <Title lang={post.languageCode} order={2}>
            {post.title}
          </Title>
          <Space h="md" />
        </>
      ) : (
        <Space h="xs" />
      )}
      <Group noWrap>
        <Avatar radius="xl" />
        <div>
          <Text weight="500">by {post.username}</Text>
          <Text weight="500">{formatDateShort(post.createdAt)}</Text>
        </div>
      </Group>
      <Space h="xl" />
      <Stack>{texts}</Stack>
      {post.nativeText && (
        <>
          <Space h="xl" />
          <Space h="xl" />
          <Stack>{nativeTexts}</Stack>
        </>
      )}
    </>
  );
}
