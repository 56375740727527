import {
  fakeUuid,
  fakeSentence,
  fakeDate,
  fakeUsername,
  randomNumber,
  fakePostText,
  fakeLanguageCode,
} from "./faker";
import { Post } from "../queries/posts";

const PERCENT_CHANCE_OF_TITLE = 0.6;
const PERCENT_CHANCE_OF_PROMPT = 0.1;
const PERCENT_CHANCE_OF_NATIVE_VERSION = 0.2;
export function fakePost(): Post {
  const createdAt = fakeDate();
  const title =
    Math.random() < PERCENT_CHANCE_OF_TITLE ? fakeSentence() : undefined;
  const prompt =
    Math.random() < PERCENT_CHANCE_OF_PROMPT ? fakeSentence() : undefined;
  const text = fakePostText();
  const nativeText =
    Math.random() < PERCENT_CHANCE_OF_NATIVE_VERSION ? text : undefined;

  return {
    id: fakeUuid(),
    title,
    text,
    nativeText,
    languageCode: fakeLanguageCode(),
    createdAt,
    updatedAt: createdAt,
    userId: fakeUuid(),
    username: fakeUsername(),
    numComments: randomNumber(0, 1),
    numCorrections: randomNumber(0, 1),
    numLikes: randomNumber(0, 2),
    prompt,
  };
}
