import {
  Avatar,
  Button,
  Card,
  createStyles,
  Grid,
  Group,
  Space,
  Text,
  Tooltip,
} from "@mantine/core";
import { Pencil } from "tabler-icons-react";
import { Post } from "../../queries/posts";
import { Link } from "react-router-dom";

const TRUNCATE_TEXT_LENGTH = 212;

const useStyles = createStyles((theme) => ({
  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  icon: {
    position: "relative",
    top: "5px",
  },

  stats: {
    position: "relative",
    bottom: "3px",
  },
}));

interface DashboardPostProps {
  post: Post;
}

export function DashboardPost({ post }: DashboardPostProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <Card withBorder={true} radius={"md"} shadow={"xs"}>
      <Grid>
        <Grid.Col xs={2}>
          <Avatar radius="xl" />
          <Text>{post.username}</Text>
        </Grid.Col>
        <Grid.Col xs={10}>
          <Text weight={700}>{post.title}</Text>
          <Text>{truncate(post.text, TRUNCATE_TEXT_LENGTH)}</Text>
          <Space h={"md"} />
          <Group>
            <Link to={`/posts/${post.id}`}>
              <Button>Proofread this</Button>
            </Link>
            {post.numCorrections > 0 && (
              <Tooltip label="Corrections" withArrow>
                <Text className={classes.stats}>
                  {post.numCorrections} <Pencil className={classes.icon} />
                </Text>
              </Tooltip>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  );
}

function truncate(input: string, length: number): string {
  if (input.length <= length) {
    return input;
  }

  return `${input.substring(0, length)}…`;
}
