import { Alert, createStyles, Indicator } from "@mantine/core";
import { Link } from "react-router-dom";
import { Inbox } from "tabler-icons-react";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    alert: {
      paddingTop: theme.spacing.md,
    },
    wrapperLink: {
      textDecoration: "none",
    },
    indicator: {
      zIndex: 1,
    },
  };
});

interface DashboardNewCorrectionsProps {
  numNewCorrections: number;
}

export function DashboardNewCorrections({
  numNewCorrections,
}: DashboardNewCorrectionsProps): JSX.Element {
  const { classes } = useStyles();
  const icon = <Inbox size={24} />;
  const indicator = numNewCorrections ? (
    <Indicator
      position={"top-end"}
      label={numNewCorrections}
      size={12}
      className={classes.indicator}
    >
      {icon}
    </Indicator>
  ) : (
    icon
  );

  return (
    <Link to="/corrections" className={classes.wrapperLink}>
      <Alert
        icon={indicator}
        title={"New corrections for you"}
        color={"green"}
        className={classes.alert}
      >
        {/**/}
      </Alert>
    </Link>
  );
}
