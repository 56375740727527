import { Textarea } from "@mantine/core";
import { Dispatch, useEffect, useRef } from "react";
import { MessageDots } from "tabler-icons-react";
import { Action } from "./SentenceForCorrection";

interface Props {
  comment: string;
  dispatch: Dispatch<Action>;
}

export function CommentField({ comment, dispatch }: Props) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      const textarea: HTMLTextAreaElement = inputRef.current;
      textarea.focus();
    }
  }, []);

  return (
    <Textarea
      description="Comment (optional)"
      ref={inputRef}
      placeholder=""
      autosize
      icon={<MessageDots size={14} />}
      onChange={(e) => {
        const comment = e.target.value;
        dispatch({ type: "COMMENT_UPDATED", payload: { comment } });
      }}
      value={comment}
    />
  );
}
