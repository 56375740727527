import { createStyles, Text } from "@mantine/core";
import { Change } from "diff";
import { sanitizeSentence } from "../../utils/formatting";
import { LanguageCode } from "../../utils/language_codes";
import { CorrectionStatus } from "./SentenceForCorrection";

const useStyles = createStyles((theme, _params, getRef) => {
  // const icon = getRef("icon");

  return {
    originalSentence: {
      del: {
        color: "#e85600",
      },
    },
  };
});

// TODO: use map function instead of for loop
function getOriginalSentenceMarkdown(changes: Change[]): string {
  let markdown = "";

  for (const change of changes) {
    if (change.added || change.removed) {
      // strikethrough removed text
      if (change.removed) {
        markdown += `<del>${change.value}</del>`;
      }
    } else {
      markdown += change.value;
    }
  }

  return markdown;
}

interface Props {
  correctionStatus: CorrectionStatus;
  languageCode: LanguageCode;
  changes: Change[];
}

export function OriginalSentence({
  correctionStatus,
  languageCode,
  changes,
}: Props): JSX.Element {
  const { classes } = useStyles();
  const originalMarkdown = getOriginalSentenceMarkdown(changes);

  const color =
    correctionStatus === "unstarted" || correctionStatus === "perfect"
      ? "default"
      : "dimmed";

  return (
    <Text
      lang={languageCode}
      color={color}
      className={classes.originalSentence}
      dangerouslySetInnerHTML={{
        __html: sanitizeSentence(originalMarkdown),
      }}
    />
  );
}
